import { PATH, OPERATOR } from "../config";
import isoDateWithoutTimeZone from "./IsoDateWithoutTimeZone";
import ym from 'react-yandex-metrika';

export default function GetFlightsData(requestParams, setFlightsData, setFlightInfo, setIsLoading, setError) {
    let thisRequestParams = { ...requestParams };
    let flightsData;
    let flightInfo;
    let url;

    if (thisRequestParams.request_id) {
        url = new URL(PATH.FLIGHTS_BY_ID + thisRequestParams.request_id);
    }
    if (thisRequestParams.card_id) {
        url = new URL(PATH.CARD_BY_ID + thisRequestParams.card_id);
    }
    if (thisRequestParams.departure_date_there) {
        thisRequestParams.departure_date_there = isoDateWithoutTimeZone(thisRequestParams.departure_date_there);
        if (thisRequestParams.departure_date_back) {
            thisRequestParams.departure_date_back = isoDateWithoutTimeZone(thisRequestParams.departure_date_back);
        }
        url = new URL(PATH.FLIGHTS);
        url.search = new URLSearchParams(thisRequestParams).toString();
    }
    if (thisRequestParams.emptyLeg) {
        delete thisRequestParams.emptyLeg;
        thisRequestParams.pax_there = 1;
        url = new URL(PATH.EMPTY_LEGS);
        url.search = new URLSearchParams(thisRequestParams).toString();
    }

    if (OPERATOR.trim() !== '') {
        if (!url) {
            url = new URL(PATH.BASE); // Assuming there's a base URL if none is set before
        }
        url.search += (url.search ? '&' : '') + `operator=${encodeURIComponent(OPERATOR)}`;
    }

    console.log('Final URL:', url);

    fetch(url)
        .then(response => response.json())
        .then(
            (result) => {
                if (thisRequestParams.card_id) {
                    flightInfo = result;
                    setFlightInfo(flightInfo);
                    return true;
                } else {
                    flightsData = result;
                    flightsData.cards = flightsData.cards.filter(item => item.routes[0].operator === OPERATOR);
                    setFlightsData(flightsData);
                    setIsLoading(false);

                    if (flightsData && flightsData.cards.length > 0) {
                        ym('reachGoal', 'SEARCH_RESULTS');
                    } else {
                        ym('reachGoal', 'EMPTY_SEARCH');
                    }
                    return true;
                }
            },
            (error) => {
                return setError(error);
            });
}
